import rangeTime from '@/components/dateTime/rangeTime.vue';
import editorText from '@/components/editorText/editorText.vue';
export default {
    data() {
        // 当一个对象比如a的堆内存类型的属性赋值给另外一个对象b的属性上时，b对象上的堆内存属性值就从a的原型上
        // 断开，用b的原型替换继承。
        // 所以下面的itemGroups.theme修改的时候，不会影响到listDeploy对象上的属性值
        let itemGroups = {
            theme: []
        };
        // setTimeout(() => {
        //     console.log(this.itemGroups);
        // }, 5000);
        return {
            itemGroups,
            // 套餐主题表单字段
            themeDeploy: {
                row: [
                    {
                        cols: 6,
                        subassembly: "VTextField",
                        property: "code",
                        connector: {
                            props: {
                                required: true,
                                label: "主题代码",
                            },
                        },
                    },
                    {
                        cols: 6,
                        subassembly: "VTextField",
                        property: "description",
                        connector: {
                            props: {
                                required: true,
                                label: "主题名称",
                            },
                        },
                    },
                    {
                        cols: 6,
                        subassembly: "WAutocomplete",
                        property: "status",
                        connector: {
                            props: {
                                required: true,
                                label: "预订状态",
                                items: [
                                    {
                                        desc: '未启动',
                                        code: 0
                                    },
                                    {
                                        desc: '启动',
                                        code: 1
                                    }
                                ]
                            },
                        }
                    }
                ]
            },
            // 套餐大类表单字段
            listDeploy: {
                row: [
                    {
                        cols: 6,
                        subassembly: "VTextField",
                        property: "code",
                        connector: {
                            props: {
                                required: true,
                                label: "套餐大类代码",
                                rules: [(v)=> {
                                    let reg = /^[0-9a-zA-Z]+$/;
                                    if(reg.test(v)) {
                                        return true;
                                    }else if(v == ""){
                                        return '套餐大类不能为空'
                                    }
                                    return "不能输入英文和数字以外";
                                }]
                            },
                        },
                    },
                    {
                        cols: 6,
                        subassembly: "VTextField",
                        property: "description",
                        connector: {
                            props: {
                                required: true,
                                label: "套餐大类名称",
                            },
                        },
                    },
                    {
                        cols: 6,
                        subassembly: "WAutocomplete",
                        property: "theme",
                        connector: {
                            props: {
                                required: true,
                                label: "套餐主题",
                                items: itemGroups.theme
                            },
                        },
                    },
                    // {
                    //     cols: 6,
                    //     subassembly: "WSelect",
                    //     property: "lsell",
                    //     connector: {
                    //         props: {
                    //             required: true,
                    //             label: "预订状态",
                    //             items: [{code: true, desc: '可预订'}, {code: false, desc: '不可预订'}]
                    //         },
                    //     },
                    // },
                    // {
                    //     cols: 6,
                    //     subassembly: "WAutocomplete",
                    //     property: "tags",
                    //     connector: {
                    //         props: {
                    //             required: true,
                    //             label: "套餐标签",
                    //         },
                    //     },
                    // },
                    {
                        cols: 6,
                        subassembly: "WAutocomplete",
                        property: "kitCodeList",
                        connector: {
                            props: {
                                required: true,
                                multiple: true,
                                chips: true,
                                label: "套餐小类",
                                // hint: '要想选择小类代码，要先同步套餐小类。',
                                // persistentHint: true,
                                items: []
                            },
                        },
                    },
                    {
                        cols: 6,
                        subassembly: rangeTime,
                        property: ["startsell","endsell"],
                        // 解构函数，应用场景：当一个组件的返回值要应用在多个表单属性上时候使用
                        deconstruction: function(args) {
                            return args.split(" ~ ")
                        },
                        connector: {
                            props: {
                                label: "起售日期 - 截止日期",
                            },
                        },
                    },
                    {
                        cols: 6,
                        subassembly: rangeTime,
                        property: ["startdate","enddate"],
                        // 解构函数，应用场景：当一个组件的返回值要应用在多个表单属性上时候使用
                        deconstruction: function(args) {
                            return args.split(" ~ ")
                        },
                        connector: {
                            props: {
                                label: "有效日期范围",
                            },
                        },
                    },
                    {
                        cols: 6,
                        subassembly: "VTextField",
                        property: "seq",
                        connector: {
                            props: {
                                // required: true,
                                label: "排序号码",
                            },
                        },
                    },
                    {
                        cols: 6,
                        subassembly: "WSwitches",
                        property: 'lsell',
                        connector: {
                            props: {

                            }
                        }
                    },
                    // {
                    //     cols: 12,
                    //     subassembly: "MaterialFile",
                    //     property: "mlistpic",
                    //     connector: {
                    //         props: {
                    //             fileType: 'commodity',
                    //             required: true,
                    //             showAddIcon: false,
                    //             hint: "大小不能超过2MB，格式仅限于jpg、png",
                    //             label: "上传图片",
                    //             title: "移动端列表图(686*320)",
                    //             // accept: "image/png, image/jpeg",
                    //         },
                    //     },
                    // },
                    {
                        cols: 12,
                        subassembly: "MaterialFile",
                        property: "mslidepics",
                        connector: {
                            props: {
                                fileType: 'banner',
                                required: true,
                                subTitle: "点击添加按钮新增上传区域，拖动选中的文件区域可对其排序，" +
                                    "图片建议：宽高比例4:3，大小不能超过2MB，格式仅限于jpg、png、jpeg、webp，数量上限为6张",
                                label: "上传图片",
                                title: "应用端轮播图",
                                // accept: "image/png, image/jpeg",
                            },
                        },
                    },
                    // {
                    //     cols: 12,
                    //     subassembly: "MaterialFile",
                    //     property: "listpic",
                    //     connector: {
                    //         props: {
                    //             fileType: 'commodity',
                    //             title: '电脑端列表图(426*605)',
                    //             hint: "大小不能超过2MB，格式仅限于jpg、png",
                    //             label: "上传图片",
                    //             showAddIcon: false,
                    //             // accept: "image/png, image/jpeg",
                    //         },
                    //     },
                    // },
                    // {
                    //     cols: 12,
                    //     subassembly: "MaterialFile",
                    //     property: "slidepics",
                    //     connector: {
                    //         props: {
                    //             fileType: 'banner',
                    //             label: "上传图片",
                    //             title: "电脑端轮播图",
                    //             hint: "大小不能超过2MB，格式仅限于jpg、png",
                    //             // accept: "image/png, image/jpeg",
                    //         },
                    //     },
                    // },
                    // {
                    //     cols: 12,
                    //     subassembly: "MaterialFile",
                    //     property: "videourl",
                    //     connector: {
                    //         props: {
                    //             title: "视频（750*375）",
                    //             limit: 1,
                    //             remark: 2,
                    //             showAddIcon: false,
                    //             subTitle: '大小不能超过50MB，格式仅限于MP4、WebM、Ogg格式'
                    //         }
                    //     },
                    // },
                    // {
                    //     cols: 6,
                    //     subassembly: "VTextField",
                    //     property: "videotime",
                    //     connector: {
                    //         props: {
                    //             // required: true,
                    //             label: "视频时长",
                    //         },
                    //     },
                    // },
                    {
                        cols: 12,
                        subassembly: "div",
                        default: "套餐简介",
                        connector: {
                            class: "form-row-title",
                        },
                    },
                    {
                        cols: 12,
                        subassembly: "VTextarea",
                        property: "introduction",
                        connector: {
                            props: {
                                solo: true,
                                rows: 2,
                                outlined: true,
                                flat: true,
                            },
                        },
                    },
                    {
                        cols: 12,
                        subassembly: 'div',
                        connector: {
                            class: 'form-row-title',
                            domProps: {
                                innerHTML: '套餐图文介绍'
                            }
                        }
                    },
                    {
                        cols: 12,
                        subassembly: editorText,
                        property: "detailtext",
                        default: "套餐图文介绍",
                        connector: {
                        },
                    },
                    {
                        cols: 12,
                        subassembly: 'div',
                        connector: {
                            class: 'form-row-title',
                            domProps: {
                                innerHTML: '预订须知'
                            }
                        }
                    },
                    {
                        cols: 12,
                        subassembly: editorText,
                        property: "noticetext",
                        default: "预订须知",
                        connector: {
                        },
                    }
                ],
            },

        }
    }
}
