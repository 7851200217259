<template>
	<div>
		<common-table
				:itemScope="['groupid', 'lsell']"
				v-bind="gather"
				:loading="loading"
				ref="ct"
				v-on="ongather"
				@beforeOpenAddDialog="beforeOpenAddDialog"
				@otherTableOperate="otherTableOperate"
		>
			<template v-slot:groupid="{ item }">
				<v-btn depressed color="primary" @click="subclass(item)">
					<v-icon size="16" class="iconfont mr-1"> iconfont icon-guanli-fill</v-icon>
					套餐小类
				</v-btn>
			</template>
			<template v-slot:lsell="{ item }">
				<w-switches
						label=""
						color="primary"
						v-model="item.lsell"
						@change="updateStatus(item.sqlid)"
						hideDetails
				></w-switches>
			</template>
		</common-table>
		<dialogTableBox
				:title="dialogTitle"
				:headers="manageHeaders"
				ref="subject"
				:commonDeploy="themeDeploy"
				type="KITTHEME"
				:width="1000"
		>
		</dialogTableBox>
		<v-dialog width="328" v-model="dialogQrcode" persistent>
			<v-card color="#F6F6FA">
				<div style="text-align: right;margin-right: 8px;">
					<v-icon size="24" @click="dialogQrcode = false">iconfont icon-guanbi</v-icon>
				</div>
				<div class="px-6 pb-6 d-flex" style="text-align: center;flex-direction: column;gap: 24px;">
					<div style="text-align: left">二维码</div>
					<v-img :src="'data:image/jpg;base64,' + qrcodeUrl"></v-img>
					<div>
						{{ qrcodeTilte }}
					</div>
					<div>
						<v-btn color="primary" depressed width="115" height="32" @click="download">下载二维码</v-btn>
					</div>
				</div>
			</v-card>
		</v-dialog>
	</div>
</template>

<script>
import commonCurd from "@/mixin/commonCurd";
import commonCurdSubjoin from "@/mixin/commonCurd-subjoin.js";
import api from "@/api/productManage/setmealManage.js";
import dialogTableBox from "@/components/dialog-table-box/dialog-table-box.vue";
import commondata from "@/api/common-data.js";
import form from "./broad-class-form.js";
import rightCode from "@/constant/rightCode.js";

export default {
	mixins: [commonCurd, commonCurdSubjoin, form],
	components: {
		dialogTableBox,
	},
	data() {
		return {
			api,
			dialogcommon: false,
			dialogQrcode: false,
			qrcodeUrl: "",
			qrcodeTilte: "",
			headers: [
				{text: "套餐大类代码", value: "code"},
				{text: "套餐大类名称", value: "description"},
				{text: "套餐主题", value: "theme"},
				{text: "套餐小类", value: "groupid"},
				{text: "排序号", value: "seq"},
				// {text: '最低价格', value: 'configFacility'},
				{text: "预订状态", value: "lsell"},
				{text: "操作", value: "actions", width: 120},
			],
			headers1: [
				{text: "主题代码", value: "code"},
				{text: "主题名称", value: "description"},
				{text: "状态", value: "status"},
				{text: "操作", value: "actions", width: 120},
			],
			headers2: [
				{text: "小类代码", value: "code"},
				{text: "小类描述", value: "description"},
				{text: "开始日期", value: "startdate"},
				{text: "结束日期", value: "enddate"},
				{text: "关联大类", value: "groupid"},
				{text: "操作", value: "actions", width: 120},
			],
			dialogTitle: "",
			manageHeaders: [],
			commonlistData: {},
			// 定义右侧按钮
			rightConfig: [
				// { label: "同步套餐小类", icon: "tongbu", event: "sync" },
				// { label: "主题管理", icon: "peitao", event: "commonlist" },
				{label: "管理套餐小类", event: "manage"},
				{
					label: "新建",
					icon: "zengjia",
					event: "add",
					rightCode: rightCode["套餐管理"].add,
				},
			],
			// 定义左侧搜索框
			leftConfig: [
				{
					type: "input",
					label: "输入套餐代码/名称/分组",
					property: "searchkey",
					width: '200px'
				},
			],
			btnActionEvent: [
				{
					event: "editItem",
					icon: "bianji2-fill",
					color: "primary",
					tooltip: "编辑",
					rightCode: rightCode["套餐管理"].load,
				},
				{
					event: "phoneView",
					icon: "iconfont icon-erweima",
					color: "primary",
					tooltip: "移动端",
				},
				// {
				//   event: "pcView",
				//   icon: "diannaoduan",
				//   color: "primary",
				//   tooltip: "电脑端",
				// },
				{
					event: "deleteItem",
					icon: "shanchu-fill",
					color: "var(--v-redness-base)",
					tooltip: "删除",
					rightCode: rightCode["套餐管理"].del,
				},
				// {
				//   event: "phoneView",
				//   icon: "yidongduan",
				//   color: "primary",
				//   tooltip: "移动端",
				// },
			],
			// 表示同步小类代码获取的状态
			groupid: true,

			// 用来给编辑下查询对应code已有的小类下拉框绑定数据
			code: "",
		};
	},

	methods: {
		download() {
			if (this.qrcodeUrl) {
				let link = document.createElement('a')
				link.href = 'data:image/jpg;base64,' + this.qrcodeUrl
				link.download = this.qrcodeTilte + '二维码.png'
				link.click()
			}
		},
		otherTableOperate(event, item) {
			if (event === "phoneView") {
				this.axios
						.post("/api/product/genqrcode", {
							param: item.code,
							pathNo: 6,
							type: "WXAPP",
						})
						.then((res) => {
							this.qrcodeUrl = res.data.base64;
							this.qrcodeTilte = item.description;
							this.dialogQrcode = true;
							console.log(res)
						});
			}
		},
		subclass(subcode) {
			this.$router.push({name: "subclass", query: {code: subcode.code}});
		},
		// 获取公共列表数据
		commonList() {
			this.axios
					.post(
							commondata.list,
							commondata.listConstruction({
								type: "kittheme",
							})
					)
					.then((res) => {
						this.commonlistData = res.data;
					});
		},
		// 同步套餐小类
		sync(v) {
			this.axios
					.post(this.select_data, {
						keyname: "kitgroup,productkit",
						params: [
							{
								key: "kitgroup",
								value: this.code,
							},
						],
					})
					.then((res) => {
						if (res.code === this.staticVal.Code.Success) {
							// 赋值表单套餐小类未选择大类的数据
							let values = [];
							if (res.data[0]?.values[0]?.children) {
								values = values.concat(res.data[0]?.values[0]?.children);
							}
							values = values.concat(res.data[1]?.values);
							console.log(values);
							this.listDeploy.row[3].connector.props.items = values;
							this.listDeploy.row[3].connector.props.hint = "";
							// this.snackbar.success("同步套餐小类成功！");
						}
					});
		},
		async otherRightOperate(event) {
			if (event === "commonlist") {
				// await this.commonList();
				this.dialogTitle = "套餐主题";
				this.manageHeaders = this.headers1;
				this.$refs.subject.dialog = true;
			} else if (event === "sync") {
				this.groupid = true;
				this.sync();
			} else if (event === "manage") {
				this.dialogTitle = "套餐管理";
				this.manageHeaders = this.headers2;
				this.$refs.subject.api.list = "/api/productkit/subclass_list";
				this.$refs.subject.api.remove = "/api/productkit/delete_productkit";
				this.$refs.subject.commonlistData.rightConfig = [];
				this.manageKit(() => {
					this.$refs.subject.dialog = true;
				});
			}
		},
		manageKit(cb) {
			cb();
		},
		beforeEdit(form) {
			this.code = form.code;
		},
		created_extend() {
			this.gather.pageTitle = "套餐大类";
			this.gather.listDeploy = this.listDeploy;
		},
		beforeOpenAddDialog() {
			if (this.groupid) {
				// this.listDeploy.row[5].connector.props.hint = undefined;
				// this.listDeploy.row[5].connector.props.persistentHint = false;
			}
			this.sync();
			return this.axios
					.post(this.select_data, {keyname: "kittheme"})
					.then((res) => {
						if (res.code === this.staticVal.Code.Success) {
							this.listDeploy.row[2].connector.props.items = res.data[0].values; // 取得主题下拉框数据
							// this.listDeploy.row[4].connector.props.items = res.data[1].values; // 取得标签下拉框数据
						}
					});
		},
	},
};
</script>
