<template>
  <exhibition-data-box
    :dialog.sync="dialog"
    :title="title"
    :width="width"
    :contentHeight="640"
  >
    <div>
      <commonTable
        :fixedHeight="550"
        :normalPage="false"
        dialogTable
        :listData="intermediary"
        :headers="headers"
        :listDeploy="listDeploy"
        :pageTitle="pageTitle"
        :loading="loading"
        @remove="remove"
        @save="save"
        @edit="edit"
        ref="ct"
        v-bind="commonlistData"
      ></commonTable>
    </div>
  </exhibition-data-box>
</template>

<script>
import props from "./dialog-table-box-props";
import exhibitionDataBox from "@/components/exhibition-data-box/exhibition-data-box.vue";
import api from "@/api/common-data.js";
// import methods from "./dialog-table-box-methods.js";
import commonCurd from "@/mixin/commonCurd";
export default {
  mixins: [props, commonCurd],
  components: {
    exhibitionDataBox,
  },
  data() {
    let fixedParams = {
      searchkey: "",
    };
    // setTimeout(() => {
    //   fixedParams.type = this.type;
    // }, 0);
    let rightConfig = [{ label: "新建", icon: "zengjia", event: "add" }];
    return {
      api,
      dialog: false,
      loading: true,
      fixedParams, // 固定参数
      commonlistData: {
        rightConfig, // 表格右侧按钮配置
        normalPage: false, // 表示在弹出框渲染表格
        btnAction: true,
        btnActionEvent: [
          {
            event: "deleteItem",
            icon: "shanchu-fill",
            color: "var(--v-redness-base)",
            tooltip: "删除",
          },
        ],
      },
      listDeploy: { row: [] },
      pageTitle: "",
    };
  },
  computed: {
    intermediary() {
      if (this.outer) {
        return this.listDataFun(this.listData);
      }
      return this.listData;
    },
  },
  methods: {
    listDataFun(listData) {
      return listData;
    },
    save(form, callback) {
      let obj = {
        sqlid: this.listDeploy.sqlid,
        type: this.type.toLocaleUpperCase(),
      };
      this.axios.post(api.save, Object.assign(obj, form)).then((res) => {
        if (res.code === this.staticVal.Code.Success) {
          callback();
          this.snackbar.success(obj.sqlid === 0 ? "新建成功！" : "保存成功！");
          this.list();
        } else {
          this.$refs.ct.$refs.formRef.load = false;
        }
      });
    },
    createdExtend() {
      this.pageTitle = this.title;
      if (!this.outer) {
        this.fixedParams.type = this.type;
      }
      this.listDeploy = this.commonDeploy;
    },
    // 编辑触发的edit函数是表单配置对象重新赋值之前
    // form=表单返回的form对象，不是listDeploy
    // async edit(form) {
    //   await this.beforeEdit(form);
    //   console.log(form);
    //   // let listDeploy = JSON.parse(JSON.stringify(this.listDeploy));
    //   // listDeploy.sqlid = form.sqlid;
    //   // for (const col of listDeploy.row) {
    //   //   let val;
    //   //   // 验证当前col行是否有表示表单属性名的属性
    //   //   if (col.property) {
    //   //     // 使用property中字符串属性名取出form对象对应属性值
    //   //     val = form[col.property];

    //   //   }
    //   // }
    //   this.listDeploy.sqlid = form.sqlid;
    //   for (const obj of this.listDeploy.row) {
    //     let val = form[obj.property];
    //     if (obj.subassembly !== "div") {
    //       delete obj.default;
    //     }
    //     if (obj.subassembly === "WFileinput") {
    //       obj.items = val;
    //     }
    //     if (obj.connector?.props) {
    //       // delete obj.connector.props.value;
    //       this.$set(obj.connector.props, "value", val);
    //     }
    //     // if (val || val === 0) {
    //     //   obj.default = val;
    //     // }
    //   }
    // },
  },
  watch: {
    dialog: {
      handler(boole) {
        if (boole) {
          this.list();
        }
      },
    },
  },
};
</script>

<style>
</style>
