export default {
    props: {
        title: {
            type: String,
            default: ""
        },
        headers: {
            type: Array,
            default: ()=> []
        },
        commonDeploy: {
            type: Object,
            default: ()=> ({row:[]})
        },
        // 表示数据保存公共类型
        type: {
            type: String,
            default: ''
        },
        // 表示不使用内置的公共数据接口，同时影响内部其他
        outer: {
            type: Boolean,
            default: false
        },
        width: {
            type: Number | String,
            default: 625
        }
    }
}